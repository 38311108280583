/* jslint browser: true */
/* global define */

/** A jQuery plugin to expand the search bar on touch/click */
define('searchExpander',['jquery'], function ($) {
	'use strict';

	$.fn.searchExpander = function (options) {
		// The settings object provides default settings.
		// The options argument can override them.
		var settings = $.extend({
			submitButton: 'button:first'
		}, options);

		var searchBox = this;
		var isHidden = true;
		var submitButton;
		var initDims = {};

		var init = function() {
			// Set up elements
			submitButton = $(settings.submitButton);
			initDims = getBoxDimensions();

			// Add event listeners
			submitButton.on('click.searchExpander', function(e) {
				if (isHidden) {
					expand();
					e.preventDefault();
				} else {
					collapse();
				}
			});

			searchBox.on('blur.searchExpander', function() {
				setTimeout(collapse, 500);
			});

			$(window).on('resize.searchExpander', function() {
				if (!searchBox.is(':focus')) {
					expand(0, false);
					searchBox.css('width', '100%');
					initDims = getBoxDimensions();
					collapse(0);
				}
			});

			// Get ready
			searchBox.parent().addClass('search-expander-wrapper');
			collapse(0);
		};

		var getBoxDimensions = function() {
			var dims = {};

			dims.width = searchBox.outerWidth();
			dims.paddingRight = searchBox.css('padding-right');
			dims.paddingLeft = searchBox.css('padding-left');
			dims.borderRight = searchBox.css('border-right');
			dims.borderLeft = searchBox.css('border-left');

			return dims;
		};

		var expand = function(duration, setFocus) {
			duration = (typeof duration !== 'undefined') ? duration : 200;
			setFocus = (typeof setFocus === 'undefined') ? true : setFocus;

			searchBox.stop().animate({
				width: initDims.width,
				paddingRight: initDims.paddingRight,
				paddingLeft: initDims.paddingLeft,
				borderRight: initDims.borderRight,
				borderLeft: initDims.borderLeft
			}, duration, function() {
				if (setFocus) {
					searchBox.focus();
				}

				isHidden = false;
				searchBox.parent().addClass('search-expanded');
			});
		};

		var collapse = function(duration) {
			duration = (typeof duration !== 'undefined') ? duration : 200;

			searchBox.stop().animate({
				width: 0,
				paddingRight: 0,
				paddingLeft: 0,
				borderRight: 0,
				borderLeft: 0
			}, duration, function() {
				isHidden = true;
				searchBox.parent().removeClass('search-expanded');
			});
		};

		// Let's go!
		init();

		// Always return the target object to allow chaining.
		return this;
	};
});

