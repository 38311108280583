/* jslint browser: true */
/* global define */

/** A jQuery plugin to move elements in the DOM based on viewport width */
define('responsiveDom',['jquery', 'matchmedia'], function ($) {
	'use strict';

	$.fn.responsiveDom = function (options) {
		// The settings object provides default settings.
		// The options argument can override them.
		var settings = $.extend({
			appendTo: 'body', // The provided object will be moved here...
			atWidth: 800,     // ...when the viewport width is this wide (px) and...
			undoAtWidth: null  // ...will revert at this width (px)
		}, options);

		var sourceEl = this;
		var placeholder = null;
		var isMoved = false;

		var init = function() {
			// Update DOM now and when window resizes
			$(window).on('resize.responsiveDom', updateDom).trigger('resize');
		};

		var updateDom = function() {
			// Check if width condition is met
			if (settings.atWidth === 0 || matchMedia('(min-width: ' + settings.atWidth + 'px)').matches) {
				// Make sure undo condition isn't met before moving
				if (!isMoved && (settings.undoAtWidth === null || matchMedia('(max-width: ' + (settings.undoAtWidth - 1) + 'px)').matches)) {
					moveElement();
					isMoved = true;
				} else if (settings.undoAtWidth !== null && isMoved && matchMedia('(min-width: ' + settings.undoAtWidth + 'px)').matches) {
					revertElement();
					isMoved = false;
				}
			} else if (isMoved) {
				revertElement();
				isMoved = false;
			}

			if (typeof settings.callback === 'function') {
				settings.callback(isMoved);
			}
		};

		var moveElement = function() {
			// Create placeholder so we can move it back if needed
			placeholder = $('<span class="responsive-dom-placeholder"></span>');
			sourceEl.after(placeholder);

			// Move element
			$(settings.appendTo).eq(0).append(sourceEl);
		};

		var revertElement = function() {
			// Move element back and remove placeholder
			placeholder.after(sourceEl);

			placeholder.remove();
			placeholder = null;
		};

		// Let's go!
		init();

		// Always return the target object to allow chaining.
		return this;
	};
});

