/* jslint browser: true */
/* global define */

/** A jQuery plugin to fix a sidebar in place as the page scrolls */
define('floatSidebar',['jquery'], function ($) {
	'use strict';

	$.fn.floatSidebar = function (options) {
		// The settings object provides default settings.
		// The options argument can override them.
		var settings = $.extend({
			update: false,  // Pass true if sidebar needs to update
			destroy: false, // Pass true to undo floating sidebar
			offset: 0       // Additional vertical offset (px)
		}, options);

		var sidebar = this;
		var sidebarDefaultHeight = 0;
		var wrapper, coords;

		var init = function() {
			// Create wrapper around sidebar children
			wrapper = $('<div class="float-sidebar-wrapper"/>');

			sidebar.append(wrapper);
			wrapper.append(sidebar.children());

			sidebar.addClass('float-sidebar-active');

			// Get coordinates
			coords = getCoords();
			sidebarDefaultHeight = getSidebarDefaultHeight();

			// CSS changes
			sidebar.css({
				position: 'relative'
			});

			wrapper.css({
				position: 'relative',
				top: 0,
				bottom: 'auto'
			});

			// Set up scroll event listener
			$(window).on('scroll.floatSidebar', positionSidebar).trigger('scroll.floatSidebar');
		};

		var getCoords = function() {
			var coords;

			coords = {
				top: sidebar.offset().top,
				bottom: sidebar.height() + sidebar.offset().top
			};

			return coords;
		};

		var getSidebarDefaultHeight = function() {
			var curHeight = sidebar.outerHeight();
			var trueHeight;

			sidebar.height('auto');

			trueHeight = sidebar.outerHeight();

			sidebar.height(curHeight);

			return trueHeight;
		};

		var positionSidebar = function() {
			var scrollTop = $(this).scrollTop();

			wrapper.css('width', sidebar.width());

			if (scrollTop < coords.top - settings.offset) {
				// Move sidebar wrapper to default position
				wrapper.css({
					position: 'absolute',
					top: 0
				});
			} else if (scrollTop > coords.bottom - settings.offset - sidebarDefaultHeight) {
				// Move sidebar wrapper to the bottom of the sidebar
				wrapper.css({
					position: 'absolute',
					bottom: 0,
					top: 'auto'
				});
			} else {
				// Float the sidebar wrapper with fixed positioning
				wrapper.css({
					position: 'fixed',
					top: 0 + settings.offset + 'px',
					bottom: 'auto'
				});
			}
		};

		var destroySidebar = function() {
			// Remove wrapper
			wrapper = sidebar.find('.float-sidebar-wrapper');

			if (wrapper.length === 1) {
				sidebar.append(wrapper.children());
				wrapper.remove();

				// Remove event listener
				$(window).off('scroll.floatSidebar');
			}
		};

		// Let's go!
		if (!settings.update && !settings.destroy) {
			init();
		}

		if (settings.update || settings.destroy) {
			destroySidebar();

			if (settings.update) {
				sidebar.floatSidebar({
					offset: settings.offset || 0
				});
			}
		}

		// Always return the target object to allow chaining.
		return this;
	};
});

