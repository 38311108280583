/* jslint browser: true */
/* global define */

/** A jQuery plugin to create a mobile menu expander */
define('mobileNav',['jquery', 'matchmedia'], function ($) {
	'use strict';

	$.fn.mobileNav = function (options) {
		// The settings object provides default settings.
		// The options argument can override them.
		var settings = $.extend({
			maxWidth: 480,     // Menu will only appear at this width or lower (px)
			appendTo: 'body',  // A selector for the element where the menu icons will appear
			text: 'Menu',      // The text for the menu launcher
			extraIcons: [],    // Extra icons to appear after the menu icon
			animDuration: 400, // Duration of menu slide animation
			callback: null     // A function to be called when menu updates
		}, options);

		var menu = this;
		var menuIsActive = false;
		var icons = null;
		var menuListItem = null;

		var init = function() {
			var debounceTimer = 0;

			// Update menu now and when the window resizes
			$(window).on('resize.mobileNav', function() {
				clearTimeout(debounceTimer);

				debounceTimer = setTimeout(updateMenu, 10);
			}).trigger('resize');
		};

		var updateMenu = function() {
			if (matchMedia('(max-width: ' + (settings.maxWidth - 1) + 'px)').matches) {
				if (!menuIsActive) {
					createMenu();
					menuIsActive = true;
				}
			} else if (menuIsActive) {
				destroyMenu();
				menuIsActive = false;
			}

			if (typeof settings.callback === 'function') {
				settings.callback(menuIsActive);
			}
		};

		var createMenu = function() {
			if (icons === null) {
				// Add menu icon (and other icons if provided)
				var iconList = $('<ul class="mobile-nav-icons"/>');

				menuListItem = $('<li class="mobile-nav-icon-menu"><a href="javascript:void(0)">' + settings.text + '</a></li>');

				iconList.append(menuListItem);

				$.each(settings.extraIcons, function(index, val) {
					iconList.append('<li class="mobile-nav-icon-' + index + '"><a href="' + val.url + '">' + val.text + '</a></li>');
				});

				$(settings.appendTo).append(iconList);

				// Add event listener to menu icon
				menuListItem.on('click.mobileNav', function() {
					menu.stop().slideToggle(settings.animDuration);
					menuListItem.toggleClass('active');
				});

				// Save handle to icon list
				icons = iconList;
			}

			icons.show();
			menu.hide();
			menu.addClass('mobile-nav-menu');
		};

		var destroyMenu = function() {
			if (icons !== null) {
				icons.hide();
				menu.show();
				menu.removeClass('mobile-nav-menu');
				menuListItem.removeClass('active');
			}
		};

		// Let's go!
		init();

		// Always return the target object to allow chaining.
		return this;
	};
});

