/* jslint browser: true */
/* global define */

/** A jQuery plugin to fix the header in place as the page scrolls */
define('floatHeader',['jquery'], function ($) {
	'use strict';

	$.fn.floatHeader = function () {
		var header = this;
		var headerParent = header.parent();
		var headerHeight = 0;
		var debounceTimer = 0;

		var init = function() {
			// Get height of header
			headerHeight = header.outerHeight();

			// Fix position
			header.css({
				position: 'fixed',
				zIndex: 10,
				top: 0,
				left: 0,
				width: '100%'
			});

			// Push rest of page down
			pushPageDown();

			// Update on window resize
			$(window).on('resize.floatHeader', function() {
				clearTimeout(debounceTimer);

				debounceTimer = setTimeout(function() {
					// Remeasure header height and push page down
					headerHeight = header.outerHeight();
					pushPageDown();
				}, 100);
			});
		};

		var pushPageDown = function() {
			headerParent.css({
				position: 'relative',
				top: headerHeight
			});
		};

		// Let's go!
		init();

		// Always return the target object to allow chaining.
		return this;
	};
});

