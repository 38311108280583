/* jslint browser: true */

/* ================================
* Application Scripts
* ================================ */
(function() {
	'use strict';

	// Add message to top of page when using outdated browsers
	require(['browserUpdate']);

	// Picturefill has a known issue with browsers that support <picture> natively
	// <https://github.com/scottjehl/picturefill/issues/356>
	// I corrected it by modifying picturefill.js manually, but my changes will be
	// overwritten by any Bower updates. Hopefully, said updates will fix it anyway.
	require(['picturefill']);

	/* -- jQuery Plugins -- */
	require(['jquery', 'queryString'], function($) {
		/* -- Global Vars -- */
		var isOldIe = $('.lt-ie9').length > 0;

		/* -- RWD Breakpoints -- */
		var breakpoints = {
			large: 960,
			medium: 800,
			small: 640,
			tiny: 480
		};

		/* -- Carousels -- */
		$(function() {
			// Don't implement carousels in Sitecore Edit mode
			if (!isSitecoreEditMode()) {
				require(['slick-carousel', 'equalHeight'], function() {
					// Set up large carousels
					var thumbnails = [];
					var carousel = $('.carousel');

					// Get thumbnails
					carousel.find('.carousel-item').each(function() {
						thumbnails.push($(this).data('thumbnail'));
					});

					// Implement slider
					carousel.slick({
						arrows: false,
						dots: true,
						autoplay: true,
						autoplaySpeed: 7000,
						adaptiveHeight: true,
						responsive: [{
							breakpoint: breakpoints.large,
							settings: {
								autoplay: false
							}
						}],
						customPaging: function(slider, i) {
							var thumbHtml = '<button class="test" type="button">' + (i + 1) + '</button>';

							if (thumbnails[i] !== '') {
								thumbHtml += '<img src="' + thumbnails[i] + '" alt=""/>';
							}

							return thumbHtml;
						},
						onBeforeChange: function(slider) {
							// Pause all videos
							slider.$slider.find('video').each(function() {
								this.pause();
							});
						}
					});

					// Cancel carousel autoplay after a video starts playing
					$('.carousel video').on('play.alpa', function() {
						carousel.slickSetOption('autoplay', false);
						carousel.find('.slick-list').off('mouseleave.slick');
					});

					// Set up small carousels
					$('.minor-carousel').slick({
						slidesToShow: 3,
						dots: true,
						arrows: false,
						responsive: [{
							breakpoint: breakpoints.medium,
							settings: {
								slidesToShow: 1
							}
						}]
					});

					$('.minor-carousel .summary-item > a').equalHeight();
				});
			}
		});

		/* -- Hamburger Menu -- */
		$(function() {
			require(['mobileNav', 'jquery.scrollTo', 'responsiveDom'], function() {
				$('.site-nav').mobileNav({
					maxWidth: breakpoints.medium,
					appendTo: '#hamburger-menu',
					text: '\uf0c9',
					animDuration: 200,
					callback: function(menuIsActive) {
						var menuIcon = $('.mobile-nav-icon-menu');

						if (menuIsActive) {
							// Scroll to top of page when menu opens
							menuIcon.on('click.alpa', function() {
								if ($(this).hasClass('active')) {
									$(window).scrollTo(0, 0, {duration: 250});
								}
							});
						}
					}
				}).responsiveDom({
					appendTo: '.header-features',
					atWidth: (!isOldIe) ? breakpoints.medium : 0
				});
			});
		});

		/* -- Search Expander -- */
		$(function() {
			require(['searchExpander', 'responsiveDom'], function() {
				var searchForm = $('#global-search');
				var submitButton = $('#global-search button');

				$('#global-search input').searchExpander({
					submitButton: '#global-search button'
				});

				// Change submit button icon
				submitButton.text('\uf002').css('font-family', 'FontAwesome');

				// Move search before Login launcher on larger viewports
				searchForm.responsiveDom({
					appendTo: '#global-search-placeholder',
					atWidth: (!isOldIe) ? breakpoints.medium : 0
				});
				
				// Modify form to use hash instead of queryString
				searchForm.submit(function() {
					var query = $('input[name="q"]', searchForm).val();
					window.location.href = searchForm.attr('action') + '#q=' + query;
					return false;
				}); 
			});
		});

		/* -- qTip (Custom tooltips) -- */
		$(function() {
			// Replace member login with qTip
			require(['jquery.qtip'], function() {
				var loginForm = $('#global-login');
				var launcher = $('<a href="javascript:void(0);" class="login-launcher">Log In</a>');

				loginForm.before(launcher);

				launcher.qtip({
					prerender: true,
					content: {
						text: loginForm
					},
					position: {
						my: 'top center',  // Position my top center...
						at: 'bottom center', // at the bottom center of...
						target: launcher // my target
					},
					show: {
						event: 'click'
					},
					hide: {
						event: 'click touchstart unfocus',
					},
					events: {
						show: function(event, api) {
							setTimeout(function() {
								loginForm.find('input:first').focus();
							}, 0);
						}
					},
					style: {
						classes: 'qtip-light qtip-shadow'
					}
				});
			});
		});

		/* -- RWD DOM Changes -- */
		$(function() {
			// Sidebar rearrangement
			require(['responsiveDom'], function() {
				var sidebar2 = $('.tertiary-content:first');

				if (sidebar2.length === 1) {
					sidebar2.responsiveDom({
						appendTo: '.secondary-content:first',
						atWidth: breakpoints.medium,
						undoAtWidth: breakpoints.large
					});
				}
			});
		});

		/* -- Equal Height Footer Sections -- */
		$(function() {
			require(['equalHeight'], function() {
				$('.footer-sitemap > div').equalHeight({
					mediaQuery: (!isOldIe) ? '(min-width: ' + breakpoints.large + 'px)' : false
				});
			});
		});

		/* -- Floating Sidebar -- */
		$(function() {
			require(['floatSidebar', 'matchmedia', 'imagesLoaded'], function() {
				var sidebar = $('.secondary-content.nav-sidebar:first');
				var sidebar2 = $('.tertiary-content:first');
				var header = $('.site-header:first');
				var sidebarHeight;
				var content, contentHeight;
				var headerOffset = 0;

				if (sidebar.length === 1) {
					// Set sidebar to same height as content and run plugin update
					var updateSidebar = function() {
						if (matchMedia('(min-width: ' + breakpoints.medium + 'px)').matches && sidebar2.length === 0
							|| matchMedia('(min-width: ' + breakpoints.large + 'px)').matches) {
							content = $('.page-content:first');
							contentHeight = content.outerHeight();
							sidebarHeight = sidebar.height();

							if (contentHeight > sidebarHeight) {
								sidebar.height(contentHeight);
							}

							// Get header offset
							headerOffset = header.outerHeight() + 20;

							// Float sidebar
							sidebar.floatSidebar({
								update: true,
								offset: headerOffset
							});
						} else {
							// Destroy floating sidebar
							sidebar.floatSidebar({destroy: true});
							sidebar.height('auto');
						}
					};

					// Initial run
					sidebar.floatSidebar();

					// Update sidebar on window resize
					$(window).on('resize.app', function() {
						updateSidebar();
					}).trigger('resize.app');

					// Update sidebar after page is fully loaded
					$('body').imagesLoaded().always(function() {
						updateSidebar();
					});
				}
			});
		});

		/* -- Floating Header -- */
		$(function() {
			// Do not float header if in Sitecore Page Editor
			if (!isSitecoreEditMode() && !isSitecorePreviewMode()) {
				require(['floatHeader', 'imagesLoaded'], function() {
					var header = $('.site-header:first');
					var headerHeight = 0;

					$('body').imagesLoaded().always(function() {
						header.floatHeader();

						// IE 8 header is half the height
						if (isOldIe) {
							headerHeight = header.height() / 2;
							header.css('height', headerHeight);
							header.after('<div style="height:' + headerHeight + 'px"></div>');
						}
					});
				});
			}
		});

		/* -- Twitter Timeline -- */
		$(function() {
			var placeholder = $('.twitter-widget');

			if (placeholder.length > 0) {
				require(['twitterWidget'], function() {
					var widgetId = placeholder.attr('data-widget-id') || '583660403479162880';
					var widgetUsername = placeholder.attr('data-twitter-username') || 'WeAreALPA';
					placeholder.twitterWidget({
						widgetId: widgetId,
						username: widgetUsername
					});
				});
			}
		});

		/* -- IE 8 Fixes -- */
		(function() {
			if (isOldIe) {
				/* -- Add Selectivizr <http://selectivizr.com/> -- */
				var script = document.createElement('script');
				script.src = (document.location.href.indexOf('localhost') > -1) ? '/javascripts/vendor/selectivizr.js' : '/Scripts/vendor/selectivizr.js';
				document.getElementsByTagName('head')[0].appendChild(script);

				/* -- Respond.js to avoid mobile layout in IE 8 -- */
				require(['respond']);
			}
		})();

		/* -- Utility Functions -- */
		function isSitecoreEditMode() {
			// Check website#sc_mode cookie for edit mode
			return document.cookie.indexOf('website#sc_mode=edit') > -1;
		}

		function isSitecorePreviewMode() {
			// Check website#sc_mode cookie for preview mode
			return document.cookie.indexOf('website#sc_mode=preview') > -1;
		}
	});
})();

define("app", function(){});

