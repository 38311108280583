/* jslint browser: true */
/* global define */

/** A jQuery plugin for Twitter's timeline widget */
define('twitterWidget',['jquery'], function ($) {
	'use strict';

	$.fn.twitterWidget = function (options) {
		var placeholder = this;
		var settings = $.extend({
			widgetId: '',
			authorHandle: ''
		}, options);

		var init = function() {
			// Add Twitter placeholder
			var twitterEl = '<a class="twitter-timeline" href="https://twitter.com/' + settings.username + '" data-widget-id="' + settings.widgetId + '">Tweets by @' + settings.username + '</a>';

			placeholder.append(twitterEl);

			// Append Twitter script (generated from https://twitter.com/settings/widgets)
			!function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0],p=/^http:/.test(d.location)?'http':'https';if(!d.getElementById(id)){js=d.createElement(s);js.id=id;js.src=p+"://platform.twitter.com/widgets.js";fjs.parentNode.insertBefore(js,fjs);}}(document,"script","twitter-wjs");
		};

		// Let's go!
		init();

		// Always return the target object to allow chaining.
		return this;
	};
});

