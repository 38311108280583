/* jslint browser: true */
/* global define */

/** A jQuery plugin to return a URL query string as an array */
define('queryString',['jquery'], function($) {
	'use strict';

	$.queryString = function(uri) {
		var vars = [], hash;
		var q = document.URL.split('?')[1];

		if (q != undefined) {
			q = q.split('&');

			for (var i = 0; i < q.length; i++) {
				hash = q[i].split('=');
				vars.push(hash[1]);
				vars[hash[0]] = hash[1];
			}
		}

		return vars;
	};
});

