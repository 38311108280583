/* jslint browser: true */
/* global define */

/** A jQuery plugin to make all target elements the same height */
define('equalHeight',['jquery', 'matchmedia'], function ($) {
	'use strict';

	$.fn.equalHeight = function (options) {
		var els = this;
		var debounceTimer = 0;
		var settings = $.extend({}, options);

		var init = function() {
			// Set all elements to tallest height
			if (!settings.mediaQuery || matchMedia(settings.mediaQuery).matches) {
				setEqualHeights();
			}

			// Resize elements on window resize
			$(window).on('resize.equalHeight', function() {
				clearTimeout(debounceTimer);

				debounceTimer = setTimeout(function() {
					if (!settings.mediaQuery || matchMedia(settings.mediaQuery).matches) {
						setEqualHeights();
					} else {
						cancelEqualHeights();
					}
				}, 250);
			});
		};

		var setEqualHeights = function() {
			var tallestHeight = 0;

			els.height('auto');
			tallestHeight = getTallestHeight();

			els.each(function() {
				var el = $(this);
				var paddingAndBorders = el.outerHeight() - el.height();

				el.height(tallestHeight - paddingAndBorders);
			});
		};

		var getTallestHeight = function() {
			var tallestHeight = 0;

			$.each(els, function() {
				var elHeight = $(this).height();

				if (elHeight > tallestHeight) {
					tallestHeight = elHeight;
				}
			});

			return tallestHeight;
		};

		var cancelEqualHeights = function() {
			els.height('auto');
		};

		// Let's go!
		if (settings.cancel) {
			cancelEqualHeights();
		} else {
			init();
		}

		// Always return the target object to allow chaining.
		return this;
	};
});

